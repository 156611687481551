@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold;
}

h2 {
  @apply text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold;
}

h3 {
  @apply text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-medium;
}

h4 {
  @apply text-xl sm:text-2xl md:text-3xl lg:text-4xl font-medium;
}

h5 {
  @apply text-lg sm:text-xl md:text-2xl lg:text-3xl font-normal;
}

h6 {
  @apply text-base sm:text-lg md:text-xl lg:text-2xl font-normal;
}


.modal-overlay {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  display: block;
  background: white;
  width: 70%;
  height: 70%;
  padding: 1rem;
  border-radius: 1rem;
}

.label-required::after {
  content: "*";
  color: red;
}
